import React, { useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { main } from "../../store/slices";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import { ErrorModal } from "../../components/Modal";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Form, Input, Button, Typography } from "antd";

const Login = () => {
	const { t } = useTranslation();
	// const navigate =useNavigate()
	const {
		setMe,
		setCollapse,
		setSelectedPath,
		setOpenMiniMenu,
		setLoginUser,
		setFirstName,
		setLastName,
		setJobtitle,
	} = main.actions;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const emailRef = useRef(null);
	const passwordRef = useRef(null);
	const [isMeLoading, setMeLoading] = useState(false);

	const errorRef = useRef();
	const getErrorRef = useCallback((ref) => {
		errorRef.current = ref;
	}, []);

	const currentUrl = window.location.href;

	const loginProfile = (values) => {
		setMeLoading(true);
		const { email, password } = values;
		axios
			.get(
				`https://protools-dashboard.bis-apps.com/api/auth?EmployeeCode=${email}&ExternalEmployeeNumber=${password}`
			)
			.then((res) => {
				axios.defaults.headers.common["Authorization"] = `Bearer ${get(
					res,
					"data.accessToken",
					""
				)}`;
				console.log(res.data);

				dispatch(setCollapse(false));
				dispatch(setSelectedPath("dashboard"));
				dispatch(setOpenMiniMenu(["dashboard"]));
				dispatch(
					setLoginUser({
						email,
						password,
					})
				);

				let aaa = {
					firstName: get(res, "data.firstName", ""),
					lastName: get(res, "data.lastName", ""),
					token: get(res, "data.accessToken", ""),
					jobTitle: get(res, "data.jobTitle", ""),
				};
				dispatch(setFirstName(get(res, "data.firstName", "")));
				dispatch(setLastName(get(res, "data.lastName", "")));
				dispatch(setJobtitle(get(res, "data.jobTitle", "")));

				dispatch(setMe(aaa));
				dispatch(setMe(aaa));
				dispatch(setMe(aaa));

				dispatch(setMe(aaa));
				navigate("/dashboard");
				setMeLoading(false);
			})
			.catch((err) => {
				setMeLoading(false);
				console.log(err);
				errorRef.current?.open("Ошибка логина или пароля");
			});
	};

	return (
		<>
			<div className="flex h-screen w-full items-center justify-center">
				<div className="flex w-[400px] items-center justify-center rounded-3xl bg-gradient-to-br from-[#05BFDB] to-[#0A4D68]">
					<Form onFinish={loginProfile} className="flex flex-col items-center gap-10">
						<Typography className="font-montserrat mt-14 text-3xl font-bold text-white">
							{t("Authorization")}
						</Typography>
						<Form.Item name="email" rules={[{ required: true, message: t("emailRequired") }]}>
							<div className="flex flex-col gap-4">
								<h3 className="font-montserrat text-base font-bold text-white">{t("Login")}</h3>
								<Input
									ref={emailRef}
									type="text"
									autoFocus
									className="h-[50px] w-[280px] rounded-xl pl-5"
								/>
							</div>
						</Form.Item>
						<Form.Item name="password" rules={[{ required: true, message: t("passwordRequired") }]}>
							<div className="flex flex-col gap-4">
								<h3 className="font-montserrat text-base font-bold text-white">{t("Password")}</h3>
								<Input.Password
									ref={passwordRef}
									required
									className="h-[50px] w-[280px] rounded-xl pl-5"
								/>
							</div>
						</Form.Item>
						<div className="mb-10">
							<Button
								type="primary"
								htmlType="submit"
								loading={isMeLoading}
								className="font-montserrat h-[50px] w-[280px] rounded-2xl bg-white text-base font-bold text-[#0A4D68]"
							>
								{t("SignIn")}
							</Button>
						</div>
					</Form>
				</div>
			</div>

			<ErrorModal getRef={getErrorRef} title={t("Ошибка логина или пароля, проверьте еще раз")} />
		</>
	);
};

export default Login;
