import React, { useState } from "react";
import { Table, Select, Button, Spin } from "antd";
import api from "../../../api/index";
import { useQuery } from "@tanstack/react-query";

export default function Tab2() {
	const [excelLoading, setExcelLoading] = useState(false);
	const [whs, setWhs] = useState("");
	const [itemGrp, setItemGrp] = useState("");
	const [expandedRowKeys, setExpandedRowKeys] = useState(null);
	const [subExpandedRowKeys, setSubExpandedRowKeys] = useState({});
	const [onExpandItemGrp, setOnExpandItemGrp] = useState("");
	const [onExpandWhs, setOnExpandWhs] = useState("");
	const [onSubExpandItemGrp, setOnSubExpandItemGrp] = useState("");
	const [onSubExpandWhs, setOnSubExpandWhs] = useState("");
	const [onSubExpandSubGrp, setOnSubExpandSubGrp] = useState("");

	const getDebitCredit = async () => {
		const { data } = await api.get("api/debitcredit/debit-credit");
		return JSON.parse(data);
	};

	const { data: debitCredit = null } = useQuery({
		queryKey: ["debitCredit"],
		queryFn: getDebitCredit,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const getTotalSum = async () => {
		const { data } = await api.get("api/inventoryitems/get-total-sum");
		return JSON.parse(data);
	};

	const { data: totalSum = [], isLoading: totalSumLoading } = useQuery({
		queryKey: ["totalSum"],
		queryFn: getTotalSum,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const getCashReports = async () => {
		const { data } = await api.get("api/cashreposts");
		return JSON.parse(data);
	};

	const { data: cashReports = [] } = useQuery({
		queryKey: ["cashReports"],
		queryFn: getCashReports,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const getInventoryItems = async ({ queryKey }) => {
		const [_, itmGrp, whs] = queryKey;
		const { data } = await api.get(
			`api/inventoryitems/get-with-group?itemGroup=${itmGrp}&whsName=${whs}`
		);
		const formattedData = JSON.parse(data).map((item, index) => ({
			...item,
			key: index,
		}));
		return formattedData;
	};

	const { data: inventoryData, isLoading: inventoryItemsLoading } = useQuery({
		queryKey: ["inventoryItems", itemGrp, whs],
		queryFn: getInventoryItems,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const getInventoryItemsByGroup = async ({ queryKey }) => {
		const [_, itmGrp, subItmGrp, whs] = queryKey;
		const { data } = await api.get(
			`api/inventoryitems/get-by-group?itemGroup=${itmGrp}&subGroup=${subItmGrp}&whsName=${whs}`
		);
		const parsedData = JSON.parse(data);

		const formattedData = parsedData.map((item, index) => ({
			...item,
			key: index,
		}));

		return formattedData;
	};

	const { data: inventoryDataByGroup, isLoading: inventoryItemsByGroupLoading } = useQuery({
		queryKey: ["inventoryItemsByGroup", onExpandItemGrp, "", onExpandWhs],
		queryFn: getInventoryItemsByGroup,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		enabled: !!onExpandItemGrp && !!onExpandWhs,
	});

	const getInventoryItemsByGroupSub = async ({ queryKey }) => {
		const [_, itmGrp, subItmGrp, whs] = queryKey;
		const { data } = await api.get(
			`api/inventoryitems/get-by-sub-group?itemGroup=${itmGrp}&subGroup=${subItmGrp}&whsName=${whs}`
		);
		const formattedData = JSON.parse(data).map((item, index) => ({
			...item,
			key: index,
		}));

		return formattedData;
	};

	const { data: inventoryDataByGroupSub, isLoading: inventoryItemsByGroupSubLoading } = useQuery({
		queryKey: ["inventoryItemsByGroupSub", onSubExpandItemGrp, onSubExpandSubGrp, onSubExpandWhs],
		queryFn: getInventoryItemsByGroupSub,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		enabled: !!onSubExpandItemGrp && !!onSubExpandWhs && !!onSubExpandSubGrp,
	});

	const getWhs = async () => {
		const { data } = await api.get("api/inventoryitems/warehouses");
		return JSON.parse(data);
	};

	const { data: wdata } = useQuery({
		queryKey: ["whs"],
		queryFn: getWhs,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const getItemGroup = async () => {
		const { data } = await api.get("api/inventoryitems/item-groups");
		return JSON.parse(data);
	};

	const { data: idata } = useQuery({
		queryKey: ["itemGroup"],
		queryFn: getItemGroup,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const handleExportExcel = async () => {
		setExcelLoading(true);
		try {
			const res = await api.post(
				"api/inventoryitems/export-to-excel",
				{},
				{
					headers: {
						"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					},
					responseType: "blob",
				}
			);

			const url = window.URL.createObjectURL(
				new Blob([res.data], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				})
			);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "OmborQoldig'i.xlsx"); // Define the download file name
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {}
		setExcelLoading(false);
	};

	const inventoryitemsColumns = [
		{
			title: "Mahsulot guruhi",
			dataIndex: "groupName",
			key: "groupName",
			filterDropdown: () => (
				<Select
					allowClear
					style={{ width: 250 }}
					placeholder="Select Description"
					value={itemGrp}
					showSearch
					optionFilterProp="children"
					filterOption={(input, option) =>
						option.children.toLowerCase().includes(input.toLowerCase())
					}
					onChange={(value) => {
						setItemGrp(value !== undefined ? value : "");
					}}
				>
					<Select.Option key={""} value={""}>
						Barchasi
					</Select.Option>
					{idata &&
						idata.map((item) =>
							item.itmsGrpNam !== null ? (
								<Select.Option key={item.itmsGrpNam} value={item.itmsGrpNam}>
									{item.itmsGrpNam}
								</Select.Option>
							) : (
								""
							)
						)}
				</Select>
			),
			onFilter: (value, record) => record.groupName === value,
		},
		{
			title: "Summa",
			dataIndex: "onHandTotal",
			key: "onHandTotal",
			text: "center",
			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Ombor",
			dataIndex: "whsName",
			key: "whsName",
			render: (text) => {
				return `${text.replace(/ToshMI/g, "Toshmi")}`;
			},
			filterDropdown: () => (
				<Select
					allowClear
					style={{ width: 150 }}
					placeholder="Select Description"
					value={whs}
					onChange={(value) => {
						setWhs(value !== undefined ? value : "");
					}}
				>
					<Select.Option key={""} value={""}>
						Barchasi
					</Select.Option>
					{wdata &&
						wdata.map((item) => (
							<Select.Option key={item.whsName} value={item.whsName}>
								{item.whsName}
							</Select.Option>
						))}
				</Select>
			),
			onFilter: (value, record) => record.whsName === value,
		},
	];

	const inventoryitemsColumnsByGroup = [
		{
			title: "Mahsulot guruhi",
			dataIndex: "groupName",
			key: "groupName",
		},
		{
			title: "Ikkinchi darajali guruhi",
			dataIndex: "subGroup",
			key: "subGroup",
		},
		{
			title: "Summa",
			dataIndex: "onHandTotal",
			key: "onHandTotal",
			text: "center",
			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Ombor",
			dataIndex: "whsName",
			key: "whsName",
			render: (text) => {
				return `${text.replace(/ToshMI/g, "Toshmi")}`;
			},
		},
	];

	const inventoryitemsColumnsByGroupSub = [
		{
			title: "Mahsulot guruhi",
			dataIndex: "groupName",
			key: "groupName",
		},
		{
			title: "Ikkinchi darajali guruhi",
			dataIndex: "subGroup",
			key: "subGroup",
		},
		{
			title: "Mahsulot brandi",
			dataIndex: "brandType",
			key: "brandType",
		},
		{
			title: "Mahsulot nomi",
			dataIndex: "itemName",
			key: "itemName",
		},
		{
			title: "Bir birlik uchun narx",
			dataIndex: "unitPrice",
			key: "unitPrice",
			text: "center",
			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Umumiy narx",
			dataIndex: "onHandTotal",
			key: "onHandTotal",
			text: "center",
			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Ombor",
			dataIndex: "whsName",
			key: "whsName",
			render: (text) => {
				return `${text.replace(/ToshMI/g, "Toshmi")}`;
			},
		},
	];

	const onExpand = async (expanded, record) => {
		if (expanded) {
			setOnExpandItemGrp(record.groupName);
			setOnExpandWhs(record.whsName);
			setExpandedRowKeys([record.key]);
		} else {
			setExpandedRowKeys([]);
		}
	};

	const onExpandSub = async (expanded, record, parentKey) => {
		if (expanded) {
			setOnSubExpandItemGrp(record.groupName);
			setOnSubExpandWhs(record.whsName);
			setOnSubExpandSubGrp(record.subGroup);
			setSubExpandedRowKeys((prevState) => ({
				...prevState,
				[parentKey]: [record.key],
			}));
		} else {
			setSubExpandedRowKeys((prevState) => ({
				...prevState,
				[parentKey]: [],
			}));
		}
	};
	return (
		<>
			{" "}
			<div className="flex items-center gap-7">
				<div className="flex h-[120px] w-[265px] flex-col gap-4 items-center rounded-3xl bg-white drop-shadow-xl">
					<h1 className="font-poppins  mt-5 text-xl font-bold text-[#425166]">
						Kreditor qarzdorlik
					</h1>

					<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
						{debitCredit?.credit &&
							new Intl.NumberFormat("fr-FR").format(debitCredit?.credit).replace(/\s/g, "  ")}{" "}
					</h1>
				</div>
			</div>
			{totalSumLoading ? (
				<div className="flex items-center justify-center mt-5">
					<Spin />
				</div>
			) : (
				<>
					<div className="grid grid-cols-4 gap-5 mt-10">
						{cashReports.map((cash, idx) => {
							return (
								<div className="flex h-[120px] w-[265px] flex-col gap-4 items-center rounded-3xl bg-white drop-shadow-xl">
									<h1 className="font-poppins  mt-5 text-xl font-bold text-[#425166]">
										{cash.acctName.replace(/ToshMI/g, "Toshmi")}
									</h1>

									<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
										{" "}
										{new Intl.NumberFormat("fr-FR")
											.format(cash.currTotal)
											.replace(/\s/g, "  ")}{" "}
									</h1>
								</div>
							);
						})}
					</div>
					<div className="grid grid-cols-4 gap-5 mt-10">
						{totalSum.map((cash, idx) => {
							return (
								<div className="flex h-[120px] w-[265px] flex-col gap-4 items-center rounded-3xl bg-white drop-shadow-xl">
									<h1 className="font-poppins  mt-5 text-xl text-center font-bold text-[#425166]">
										{cash.whsName.replace(/ToshMI/g, "Toshmi")} mahsulot
									</h1>

									<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
										{" "}
										{new Intl.NumberFormat("fr-FR")
											.format(cash.onHandTotal)
											.replace(/\s/g, "  ")}{" "}
									</h1>
								</div>
							);
						})}
					</div>
				</>
			)}
			<div className="flex  items-center justify-between mt-20">
				<h1 className="text-lg  font-semibold">Ombor qoldig'i</h1>

				<div>
					{" "}
					<Button
						onClick={handleExportExcel}
						loading={excelLoading}
						className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[200px]"
					>
						Excelga ko'chirish
					</Button>
				</div>
			</div>
			<Table
				columns={inventoryitemsColumns}
				dataSource={inventoryData}
				rowKey={(record) => record.key}
				pagination={false}
				loading={inventoryItemsLoading}
				className="mt-10"
				expandedRowKeys={expandedRowKeys}
				onExpand={onExpand}
				expandedRowRender={(record) => (
					<Table
						columns={inventoryitemsColumnsByGroup}
						dataSource={inventoryDataByGroup}
						rowKey={(record) => record.key}
						expandedRowKeys={subExpandedRowKeys[record.key] || []}
						pagination={false}
						loading={inventoryItemsByGroupLoading}
						onExpand={(expanded, subRecord) => onExpandSub(expanded, subRecord, record.key)}
						expandedRowRender={(record) => (
							<Table
								columns={inventoryitemsColumnsByGroupSub}
								dataSource={inventoryDataByGroupSub}
								rowKey={(record) => record.key}
								pagination={false}
								loading={inventoryItemsByGroupSubLoading}
							/>
						)}
					/>
				)}
			/>
		</>
	);
}
