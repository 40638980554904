import React, { useState, useEffect, useCallback } from "react";
import { Table, Input, Spin } from "antd";
import api from "../../../api/index";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";

const today = moment().format("YYYY-MM-DD");

export default function Tab3() {
	const [salesAnalysGrp, setSalesAnalysGrp] = useState("");
	const [currentPageSales, setCurrentPageSales] = useState(0);
	const [startDatePnl, setStartDatePnl] = useState(moment().startOf("month").format("YYYY-MM-DD"));
	const [endDatePnl, setEndDatePnl] = useState(today);

	const getPnlReports = async ({ queryKey }) => {
		const [_, startDate, endDate] = queryKey;
		const { data } = await api.get(`api/pnlreport?startDate=${startDate}&endDate=${endDate}`);
		return JSON.parse(data);
	};

	const { data: pnlReports, isLoading: loadingPnl } = useQuery({
		queryKey: ["pnlReports", startDatePnl, endDatePnl],
		queryFn: getPnlReports,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const getSalesAnalysis = async ({ queryKey }) => {
		const [_, itmGrp] = queryKey;
		const { data } = await api.get(`api/salesanalysis?groupName=${itmGrp}`);
		return JSON.parse(data);
	};

	const { data: salesAnalysisData, isLoading: loading } = useQuery({
		queryKey: ["salesAnalysis", salesAnalysGrp],
		queryFn: getSalesAnalysis,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const handleInputChangeSalesAnalys = useCallback((value) => {
		setCurrentPageSales(0);
		setSalesAnalysGrp(value);
	}, []);

	const salesAnalysColumns = [
		{
			title: "Mahsulot guruhi",
			dataIndex: "group",
			key: "group",
			filterDropdown: ({ confirm, dataIndex }) => (
				<div className="p-2">
					<Input
						placeholder="Mahsulot bo'yicha qidirish"
						value={salesAnalysGrp}
						onChange={(e) => {
							handleInputChangeSalesAnalys(e.target.value ? e.target.value : "");
							// handleSearch(e, confirm, dataIndex);
						}}
						style={{ width: 200, marginBottom: 8, display: "block" }}
					/>
				</div>
			),
			onFilter: (value, record) => record.group === value,
		},

		{
			title: "Soni",
			dataIndex: "quantity",
			key: "quantity",
			text: "center",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Summa",
			dataIndex: "sales",
			key: "sales",
			text: "center",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Foyda",
			dataIndex: "profit",
			key: "profit",
			text: "center",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText}`;
			},
		},
		{
			title: "Foyda foizda",
			dataIndex: "grossPercent",
			key: "grossPercent",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text).replace(/\s/g, "   ");
				return `${formattedText} %`;
			},
		},
	];
	return (
		<>
			<h1 className="text-lg mt-10 font-semibold ml-2">P&L</h1>
			<div className="flex items-center gap-8 mt-5">
				<div className="flex flex-col gap-2">
					<p>Boshlang'ich sana</p>
					<Input
						type="date"
						value={startDatePnl}
						className="w-[150px]"
						onChange={(e) => {
							setStartDatePnl(e.target.value);
						}}
						max={endDatePnl}
					/>
				</div>
				<div className="flex flex-col gap-2">
					<p>Tugash sanasi</p>
					<Input
						type="date"
						value={endDatePnl}
						className="w-[150px]"
						onChange={(e) => {
							setEndDatePnl(e.target.value);
						}}
						min={startDatePnl}
					/>
				</div>
			</div>
			{loadingPnl ? (
				<div className="flex items-center justify-center mt-5">
					<Spin />
				</div>
			) : (
				<>
					<div className="grid grid-cols-4 gap-5 mt-10">
						{pnlReports.map((cash, idx) => {
							return (
								<div className="flex h-[120px]  w-[250px] flex-col gap-4 items-center rounded-3xl bg-white drop-shadow-xl">
									<h1 className="font-poppins  mt-5 text-xl font-bold text-[#425166]">
										{cash.acctName === "Arrenda do'kon (toshmi)"
											? "Arenda do'kon (toshmi)"
											: cash.acctName === "SofFoyda"
											? "Sof foyda"
											: cash.acctName === "Umumiy Rasxodlar"
											? "Umumiy Chiqimlar"
											: cash.acctName === "Arrenda do'kon (archa)"
											? "Arenda do'kon (archa)"
											: cash.acctName.replace(/rasxod/g, "chiqim")}
									</h1>

									<h1 className="font-poppins  text-xl font-bold text-[#151D48]">
										{" "}
										{new Intl.NumberFormat("fr-FR").format(cash.summa).replace(/\s/g, "  ")}{" "}
									</h1>
								</div>
							);
						})}
					</div>
				</>
			)}

			<h1 className="text-lg mt-10 font-semibold">Sotuv analizi </h1>
			<div>
				<Table
					columns={salesAnalysColumns}
					dataSource={salesAnalysisData}
					pagination={false}
					className="mt-6 w-full"
					bordered
					sticky={true}
					loading={loading}
				/>
			</div>
		</>
	);
}
