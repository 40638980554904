import React, { useState, useEffect, useCallback } from "react";
import { Input, Space, Table, Select } from "antd";
import Layout from "../../components/Layout/index";
import api from "../../api/index";
import moment from "moment";
import Tab1 from "../../components/Dashboard/Tab-1/index";
import Tab3 from "../../components/Dashboard/Tab-3/index";
import Tab2 from "../../components/Dashboard/Tab-2/index";

export default function Dashboard() {
	const [activeTab, setActiveTab] = useState("tab1");

	const renderContent = () => {
		switch (activeTab) {
			case "tab1":	
				return <Tab1 />;
			case "tab2":
				return <Tab2 />;
			case "tab3":
				return (
					<>
						<Tab3 />
					</>
				);

			default:
				return null;
		}
	};

	return (
		<Layout>
			<div className="m-5">
				<h1 className="text-2xl font-bold">Dashboard</h1>
				<Space style={{ marginBottom: 16 }} className="mt-10">
					<button
						className={`${
							activeTab === "tab1" ? "text-blue-900 underline underline-offset-8" : ""
						} font-semibold `}
						onClick={() => setActiveTab("tab1")}
					>
						Sotuv ko'rsatkichlari
					</button>
					<button
						className={`${
							activeTab === "tab2" ? "text-blue-900 underline underline-offset-8" : ""
						} font-semibold ml-3`}
						onClick={() => setActiveTab("tab2")}
					>
						Moliyaviy ko'rsatkichlar
					</button>
					<button
						className={`${
							activeTab === "tab3" ? "text-blue-900 underline underline-offset-8" : ""
						} font-semibold ml-3`}
						onClick={() => setActiveTab("tab3")}
					>
						Moliyaviy hisobotlar
					</button>
				</Space>
				<div className="mt-10">{renderContent()}</div>
			</div>
		</Layout>
	);
}
