import styled from 'styled-components';
import colors from '../../assets/style/colors';

const LayoutStyle = styled.div`
  transition: 0.3s;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .headerContainer {
    /* background-color: green; */
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    -webkit-box-shadow: 4px 4px 17px -8px rgba(62, 62, 62, 0.15);
    -moz-box-shadow: 4px 4px 17px -8px rgba(62, 62, 62, 0.15);
    box-shadow: 4px 4px 17px -8px rgba(62, 62, 62, 0.15);
    .userImg {
      width: 40px;
      height: 40px;
      background-color: #ececec;
      border-radius: 40px;
      margin-left: 20px;
      cursor: pointer;
      border: 1px solid #ffffff;
    }
    .userImg:hover {
      border: 1px solid ${colors.darkMainColor};
    }
    .userName {
      margin: 0;
      padding: 0;
      color: ${colors.darkMainColor};
      font-weight: 600;
      font-size: 17px;
      text-align: end;
    }
    .userPhone {
      margin: 0;
      padding: 0;
      color: ${colors.darkGray};
      font-weight: 500;
      font-size: 14px;
    }
  }
  .containerr {
    width: 100%;
    transition: 0.3s;
  }
  .container2 {
    width: 88%;
    transition: 0.3s;
  }
  .drawer {
    width: 6%;
    background-image: linear-gradient(to right bottom, #0b6885, #0a4d68);
    -webkit-box-shadow: 0px 0px 43px 0px rgba(34, 60, 80, 0.06);
    -moz-box-shadow: 0px 0px 43px 0px rgba(34, 60, 80, 0.06);
    box-shadow: 0px 0px 43px 0px rgba(34, 60, 80, 0.06);
    transition: 0.3s;
    position: relative;
  }
  .miniBntContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .drawer2 {
    width: 12%;
    background-image: linear-gradient(to right top, #0b6885, #0a4d68);
    transition: 0.3s;
    -webkit-box-shadow: 0px 0px 43px 0px rgba(34, 60, 80, 0.06);
    -moz-box-shadow: 0px 0px 43px 0px rgba(34, 60, 80, 0.06);
    box-shadow: 0px 0px 43px 0px rgba(34, 60, 80, 0.06);
    position: relative;
  }
  .mainBtn {
    background-color: transparent;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
  }
  .btn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    cursor: pointer;
    width: 90%;
    /* justify-content: center; */
    transition: 300ms;
    border-radius: 10px;
  }
  .btn3 {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    cursor: pointer;
    width: 90%;
    justify-content: center;
    transition: 300ms;
    border-radius: 10px;
  }
  .btn:hover {
    background-color: ${colors.darkMainColor};
  }

  .btn2 {
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    width: 90%;
    cursor: pointer;
    transition: 300ms;
  }
  .btn22 {
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    width: 90%;
    cursor: pointer;
    transition: 300ms;
  }
  .btnTitle {
    color: #ffffff;
    font-size: 18px;
    margin-left: 10px;
    transition: 0.3s;
    transform: scale(1);
  }
  .none {
    display: none;
    transition: 0.3s;
  }
  .btnTitle2 {
    color: ${colors.mainColor};
    font-size: 18px;
    margin-left: 10px;
  }
  .btnCard {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .mainmenu {
    background-color: transparent;
    margin: auto;
  }
  .companyTitle {
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
  }
  .companyCard {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .relativee{
    padding: 20px 0;
  }

  && .ant-menu-item-selected {
    background-color: ${colors.lightMainColor};
  }

  && .ant-menu-title-content {
    color: #ffffff !important;
  }
  && .ant-menu-submenu-arrow {
    color: #ffffff !important;
  }
`;
export default LayoutStyle;
