import styled from 'styled-components';

const Styles = styled.div`
  .modal {
    background-color: #00000077;
    position: fixed;
    top: 0%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card {
    text-align: center;
    padding: 10px;
    min-width: 300px;
  }
  .btnY {
    padding: 10px;
    border-radius: 5px;
    border: 3px solid #d40000;
    color: #ffffff;
    transition: 0.2s;
    font-weight: 600;
    font-size: 17px;
    min-width: 100px;
    background-color: #d40000;
  }
  .btnY:hover {
    box-shadow: 3px 5px 15px #e2e2e2;
  }
  .btnN {
    padding: 10px;
    border-radius: 5px;
    border: 3px solid #00a341;
    color: #ffffff;
    transition: 0.2s;
    font-weight: 600;
    font-size: 17px;
    min-width: 100px;
    margin-right: 10px;
    background-color: #00a341;
  }
  .btnN:hover {
    box-shadow: 3px 5px 15px #e2e2e2;
  }
  .img {
    width: 100px;
  }
  .centerCard {
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
`;
export default Styles;
