import {createSlice} from '@reduxjs/toolkit';

export const main = createSlice({
  name: 'main',
  initialState: {
    getMe: {},
    language: 'uz',
    firstName: '',
    lastName: '',
    jobTitle: '',

    token: '',
    collapse: false,
    selectedPath: 'purchaseRequest',
    openMiniMenu: ['procurementMenu'],
    warehouses: [],
    loginUser: {},
  },
  reducers: {
    setMe: (state, action) => {
      state.getMe = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setJobtitle: (state, action) => {
      state.jobtitle = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setCollapse: (state, action) => {
      state.collapse = action.payload;
    },
    setSelectedPath: (state, action) => {
      state.selectedPath = action.payload;
    },
    setOpenMiniMenu: (state, action) => {
      state.openMiniMenu = action.payload;
    },
    setWarehouses: (state, action) => {
      state.warehouses = action.payload;
    },
    setLoginUser: (state, action) => {
      state.loginUser = action.payload;
    },
  },
});
